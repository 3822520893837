import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import useAxios from 'axios-hooks'
import LandingPage from '../LandingPage/LandingPage'
import Loading from '../Loading/Loading'
import Button, { buttonStyle } from '../Button/Button';
import StatusDisplayMultipassError from './StatusDisplayMultipassError';
import { logNewRelicPageAction } from '../../utils/logNewRelicPageAction';
import { getShopSiteCookie } from '../../utils/configCookies';

export const errorMessageSuggestion = <>If you are having issues logging in, please contact: { 
  getShopSiteCookie() === "/sg" 
    ? <a href="mailto:the.van.sg@nike.com">the.van.sg@nike.com</a> 
    : <a href="mailto:the.van.1@nike.com">the.van.1@nike.com</a> 
}</>;

type LoginProps = {
 urls: {
  shopifyUrl: string,
  apiUrl: string
 }
}

const Login = ({ urls }:LoginProps) => {
  
  const { authState, authService } = useOktaAuth();

  const multipassRequestHeaders = {
    'X-Region-Code': sessionStorage['shopSite'] ? sessionStorage['shopSite'].replace(/\//g, '') : ''
  } 

  const [
    { data: postData, loading: postLoading, error: postError },
    executePost,
  ] = useAxios(
    {
      url: urls.apiUrl,
      method: "POST",
      headers: multipassRequestHeaders
    },
    { manual: true }
  )

  const errorMessage = postError?.message && `Sorry, error: ${postError.message} occurred. Please try again later.`
 
  const statusConnectingOkta = !authState.isAuthenticated && authState.isPending;
  const statusConnectingMultipass = postLoading;
  const statusReceivedMultipass = postData;
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const login = async () => await authService.login('/');
  const onLogoutRoute = window.location.href.includes("logout");
  const loggedOutMessage = onLogoutRoute && !authState.isAuthenticated ? "Logout Successful" : null
  const loginButton = (        
    <Button
      style={{...buttonStyle, cursor:'pointer'}}
      onClick={() => {
        logNewRelicPageAction('ceLoginButtonClicked', 'Login Button Clicked');
        setIsButtonLoading(true);
        login();
      }}
      isLoading={isButtonLoading}
    >
      Continue To Login
    </Button>
  );

  const logStatus = () => {
    console.info({statusConnectingOkta});
    console.info({statusConnectingMultipass});
    console.info({statusReceivedMultipass: JSON.stringify(statusReceivedMultipass)});
  };

  useEffect(() => { 
    if(authState.idToken && onLogoutRoute){

      authService.clearAuthState();
      logNewRelicPageAction('ceLoginStage', 'Logout');
    
    } else if(authState.idToken && !postData){    
      
      logNewRelicPageAction('ceLoginStage', 'Okta Authorized');

      executePost({
        data: {
          token: authState.idToken,
          return_url: urls.shopifyUrl,
        },
      })
    } 

    if (statusReceivedMultipass?.url.includes("multipass")) {
      logNewRelicPageAction('ceLoginStage', 'Multipass Redirect');
      window.location.href = statusReceivedMultipass.url; 
    }
  }, [authState,executePost,urls.shopifyUrl,statusReceivedMultipass, postData, onLogoutRoute, authService]);

  const render = () => {
    if (!authState.isAuthenticated && authState.isPending && !onLogoutRoute){
      return (
        <Loading styles={buttonStyle}>Checking Okta...</Loading>
      )
    } else if (!postData && !postLoading && !postError){
      return (
        <>
          {loginButton}
        </>
      )
    } else if (postError || (statusReceivedMultipass?.url  === "")){
      logStatus();
      logNewRelicPageAction('ceLoginStage', `Lambda Error: ${postError && postError.message}`);
      return (
        <>
          <StatusDisplayMultipassError>
            <p>
              {errorMessage}
            </p>
            {errorMessageSuggestion && <p>{errorMessageSuggestion}</p>}
          </StatusDisplayMultipassError>
          {loginButton}
        </>
      )
    } else {
      logStatus();
      return (
        <div style={{padding:10, height: "-webkit-fill-available"}}>
          <div>
            {postData && !postLoading ?
              (<div style={{paddingBottom:10}}><a href={postData.url} style={buttonStyle}>Redirecting To The vAn...</a></div>) :
              (
                <Button
                  style={buttonStyle}
                  onClick={() => {}}
                  isLoading={true}
                >
                  Loading...
                </Button>
              )
            }
          </div>
        </div>
      )
    }
  }

  return (
    <LandingPage headerMessage={loggedOutMessage}>
      {render()}
    </LandingPage>
  );
}

export default Login;
