import { setShopSiteCookie, getShopSiteCookie } from './configCookies'
import { getConfigUrls } from './getConfigUrls';

export const shopSites = ["sg"]; // additional site codes (ie. 2-digit ISO 3166), US default not listed 
export const validReferrerHosts = [
  "thevan.nike.com",
  "thevan-sg.nike.com",
  "myshopify.com"
];

export const shopSiteLoginPaths = shopSites.map(site=>`/${site}`);

export const shopSitePathCurrent = shopSiteLoginPaths.find((path)=>{
  return window.location.pathname === path
});

export const shopPath = sessionStorage['shopSite']?.replace(/\//g, "") || '';

export function getEnvironmentByHostname(hostname:string) {

  setShopSiteCookie();

  const scopes = ["openid", "profile", "email","iam.okta.address.read","iam.okta.job.read"];
  const redirectUri = `https://${hostname}/implicit/callback`;
  const redirectUriLocal = `http://localhost:3000/implicit/callback/`;
  const issuer = "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7";
  const issuerUriProd = "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7";
  const clientId = 'nike.natech.thevan-multipass-browser';

  const oidc = { clientId, issuer, redirectUri, scopes }
  
  if (hostname === "thevanlogin.nike.com") {
    return { oidc: {...oidc ,issuer: issuerUriProd }, urls: getConfigUrls('prod', getShopSiteCookie())}
  } else if (hostname === "thevanlogin-uat.nike.com") {
    return { oidc, urls: getConfigUrls('uat', getShopSiteCookie())}
  } else if (hostname === "thevanlogin-dev.nike.com") {
    return { oidc, urls: getConfigUrls('dev', getShopSiteCookie())}
  } else if (hostname === "localhost") {
    return { oidc: {...oidc ,redirectUri: redirectUriLocal }, urls: getConfigUrls('local', getShopSiteCookie())}
  } else {
    return { oidc, urls: getConfigUrls('qa',getShopSiteCookie())}
  }
}